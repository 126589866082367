/**
 * Cookie management functions
 */
export const cookieFunctions = {

  /**
   * Create a cookie
   * @param name
   * @param value
   * @param days
   */
  create: function(name, value, days) {
      var expires = "";
      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + value + expires + "; path=/";
  },

  /**
   * Read a cookie
   * @param name
   */
  read: function(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
          if (ca[i] != 0) {
              var c = ca[i];
              while (c.charAt(0) == ' ') c = c.substring(1, c.length);
              if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
          }
      }
      return null;
  },

  /**
   * Delete a cookie
   * @param name
   */
  erase: function(name) {
      this.create(name, "", -1);
  },



};
