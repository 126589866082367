import React from "react";

class FeaturedPromotions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    let isOpen = true;

    if (!localStorage["viewFeaturedPromotions"]) {
      localStorage["viewFeaturedPromotions"] = "CLOSED";
    } else if (localStorage["viewFeaturedPromotions"] === "CLOSED") {
      isOpen = false;
    }
    const self = this;
    setTimeout(() => {
      self.setState({ open: isOpen });
    }, 250);
  }

  getPromotionIcon = (promotion) => {
    const { pageDesign } = this.props;
    const styles = pageDesign;
    const defaults = pageDesign.defaults;

    let media = null;
    if (promotion.action) {
      switch (promotion.action.function_reference) {
        case "email_discount_code":
          media = (
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M11.566.66a2.189 2.189 0 00-3.132 0l-.962.985a2.189 2.189 0 01-1.592.66l-1.377-.017a2.189 2.189 0 00-2.215 2.215l.016 1.377a2.189 2.189 0 01-.66 1.592l-.984.962a2.189 2.189 0 000 3.132l.985.962c.428.418.667.994.66 1.592l-.017 1.377a2.189 2.189 0 002.215 2.215l1.377-.016a2.189 2.189 0 011.592.66l.962.984c.859.88 2.273.88 3.132 0l.962-.985a2.189 2.189 0 011.592-.66l1.377.017a2.189 2.189 0 002.215-2.215l-.016-1.377a2.189 2.189 0 01.66-1.592l.984-.962c.88-.859.88-2.273 0-3.132l-.985-.962a2.189 2.189 0 01-.66-1.592l.017-1.377a2.189 2.189 0 00-2.215-2.215l-1.377.016a2.189 2.189 0 01-1.592-.66L11.566.66zM7 8.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm6 6a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm.778-8.278a1.1 1.1 0 010 1.556l-6 6a1.1 1.1 0 11-1.556-1.556l6-6a1.1 1.1 0 011.556 0z"
                fill={styles.color.primary || defaults.color.primary}
              />
            </svg>
          );
          break;
        case "send_email":
          media = (
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 5.324V15.5A1.5 1.5 0 001.5 17h17a1.5 1.5 0 001.5-1.5V5.324l-9.496 5.54a1 1 0 01-1.008 0L0 5.324z"
                fill={styles.color.primary || defaults.color.primary}
              />
              <path
                d="M19.443 3.334A1.494 1.494 0 0018.5 3h-17c-.357 0-.686.125-.943.334L10 8.842l9.443-5.508z"
                fill={styles.color.primary || defaults.color.primary}
              />
            </svg>
          );
          break;
        case "post_customer_info":
          media = (
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.1 14.95c.412.16.9-.08.9-.45v-13c0-.37-.488-.61-.9-.45-.683.41-1.311.83-1.919 1.237-2.14 1.43-4.019 2.687-7.084 2.713H4C2.34 5 1 6.34 1 8s1.34 3 3 3h4c3.123 0 5.02 1.268 7.182 2.714.607.406 1.236.826 1.918 1.236zM9 18h-.79c-.43 0-.81-.27-.94-.67L5.07 13H9a1 1 0 011 1v3c0 .55-.45 1-1 1z"
                fill={styles.color.primary || defaults.color.primary}
              />
            </svg>
          );
          break;
        case "send_checkout_link":
          media = (
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill={styles.color.primary || defaults.color.primary}
                fill-rule="evenodd"
                d="M5 0a1 1 0 000 2h1v1a1 1 0 00.917.997l10.943.911L17.133 10H7a1 1 0 00-1 1v3.17A3.001 3.001 0 109.83 16h3.34A3 3 0 1016 14H8v-2h10a1 1 0 00.99-.859l1-7a1 1 0 00-.907-1.138L8 2.08V1a1 1 0 00-1-1H5zm11 16a1 1 0 100 2 1 1 0 000-2zm-9 0a1 1 0 100 2 1 1 0 000-2z"
              />
              <path
                fill={styles.color.primary || defaults.color.primary}
                d="M0 3a1 1 0 011-1h1a1 1 0 010 2H1a1 1 0 01-1-1zM1 6a1 1 0 000 2h6a1 1 0 000-2H1zM0 11a1 1 0 011-1h2a1 1 0 110 2H1a1 1 0 01-1-1zM1 14a1 1 0 100 2h1a1 1 0 100-2H1z"
              />
            </svg>
          );
          break;
        case "send_abandoned_checkout_link":
          media = (
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.707 1.293a1 1 0 00-1.414 1.414L9.586 4 8.293 5.293a1 1 0 001.414 1.414L11 5.414l1.293 1.293a1 1 0 101.414-1.414L12.414 4l1.293-1.293a1 1 0 00-1.414-1.414L11 2.586 9.707 1.293z"
                fill={styles.color.primary || defaults.color.primary}
              />
              <path
                fill-rule="evenodd"
                d="M1 1a1 1 0 011-1h1.5A1.5 1.5 0 015 1.5V10h11.133l.877-6.141a1 1 0 111.98.282l-.939 6.571A1.5 1.5 0 0116.566 12H5v2h10a3 3 0 11-2.83 2H6.83A3 3 0 113 14.17V2H2a1 1 0 01-1-1zm13 16a1 1 0 112 0 1 1 0 01-2 0zM3 17a1 1 0 112 0 1 1 0 01-2 0z"
                fill={styles.color.primary || defaults.color.primary}
              />
            </svg>
          );
          break;
        case "send_invite_with_reward":
          media = (
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 11a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1zM12.363 5.22a4.22 4.22 0 11-8.439 0 4.22 4.22 0 018.439 0zM.67 14.469c1.385-1.09 4.141-2.853 7.474-2.853 3.332 0 6.089 1.764 7.474 2.853.618.486.81 1.308.567 2.056l-.333 1.02A2.11 2.11 0 0113.845 19H2.441a2.11 2.11 0 01-2.005-1.455l-.333-1.02c-.245-.748-.052-1.57.567-2.056z"
                fill={styles.color.primary || defaults.color.primary}
              />
            </svg>
          );
          break;
        default:
          media = (
            <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 11a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1zM12.363 5.22a4.22 4.22 0 11-8.439 0 4.22 4.22 0 018.439 0zM.67 14.469c1.385-1.09 4.141-2.853 7.474-2.853 3.332 0 6.089 1.764 7.474 2.853.618.486.81 1.308.567 2.056l-.333 1.02A2.11 2.11 0 0113.845 19H2.441a2.11 2.11 0 01-2.005-1.455l-.333-1.02c-.245-.748-.052-1.57.567-2.056z"
                fill={styles.color.primary || defaults.color.primary}
              />
            </svg>
          );
          break;
      }
    }

    return media;
  };

  render() {
    const { pageDesign, promotions, customerId, locale } = this.props;
    const { open } = this.state;

    const styles = pageDesign;
    const defaults = pageDesign?.defaults || {};

    // card shadows
    const shadows = pageDesign.shadows || defaults.shadows;
    let cardShadows = "none";
    switch (shadows) {
      case "light":
        cardShadows =
          "0px 0px 5px rgba(23, 24, 24, 0.05), 0px 1px 2px rgba(0, 0, 0, 0.15)";
        break;
      case "dark":
        cardShadows =
          "0px 0px 10px rgba(23, 24, 24, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.45)";
        break;
    }

    // card border
    const radiusStyle = styles.radius_style || defaults.radius_style;
    let cardBorder = "1px";
    let buttonBorder = "1px";
    switch (radiusStyle) {
      case "soft":
        buttonBorder = "0.4rem";
        cardBorder = "0.8rem";
        break;
      case "rounded":
        buttonBorder = "0.8rem";
        cardBorder = "1.6rem";
        break;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          "--ea-primary-color": styles?.color?.primary || defaults?.color?.primary,
          "--ea-card-border":
            (styles?.border?.width || defaults.border.width) +
            "px solid " +
            (styles?.color?.card_border || defaults?.color?.card_border),
          "--ea-card-background": styles?.color?.card || defaults?.color?.card,
          "--ea-card-shadow": cardShadows,
          "--ea-border-radius": cardBorder,
          "--ea-button-border-radius": buttonBorder,
        }}
      >
        <div className="ea-featured-promotions-cards" hidden={!open}>
          <div className="ea-featured-promotions-card" key={"promotionsTitle"}>
            <div className="ea-featured-promotions-title">
              <h1>{locale.promotions.featured_title}</h1>
            </div>
          </div>
          {promotions.featured && Array.isArray(promotions.featured) && promotions.featured.map((promotion) => (
            <div className="ea-featured-promotions-card" key={promotion.id}>
              <div className="ea-featured-promotions-icon">
                {this.getPromotionIcon(promotion)}
              </div>
              <div className="ea-featured-promotions-title">
                <h2>{promotion.title}</h2>
                <span>{promotion.description}</span>
              </div>
            </div>
          ))}
          <div className="ea-featured-promotions-card">
            {customerId ? (
              <div className="ea-promotion-link">
                <div>{locale.promotions.go_to_account}</div>
                <a href="/a/account">{locale.promotions.my_account}</a>
              </div>
            ) : (
              <div className="ea-promotion-link">
                <div>{locale.promotions.log_in_to_see}</div>
                <a href="/account/login">{locale.promotions.log_in}</a>
              </div>
            )}
          </div>
        </div>
        <button
          className="ea-featured-promotions-button"
          onClick={() => {
            this.setState({ open: !open });
          }}
        >
          <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M11.566.66a2.189 2.189 0 00-3.132 0l-.962.985a2.189 2.189 0 01-1.592.66l-1.377-.017a2.189 2.189 0 00-2.215 2.215l.016 1.377a2.189 2.189 0 01-.66 1.592l-.984.962a2.189 2.189 0 000 3.132l.985.962c.428.418.667.994.66 1.592l-.017 1.377a2.189 2.189 0 002.215 2.215l1.377-.016a2.189 2.189 0 011.592.66l.962.984c.859.88 2.273.88 3.132 0l.962-.985a2.189 2.189 0 011.592-.66l1.377.017a2.189 2.189 0 002.215-2.215l-.016-1.377a2.189 2.189 0 01.66-1.592l.984-.962c.88-.859.88-2.273 0-3.132l-.985-.962a2.189 2.189 0 01-.66-1.592l.017-1.377a2.189 2.189 0 00-2.215-2.215l-1.377.016a2.189 2.189 0 01-1.592-.66L11.566.66zM7 8.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm6 6a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm.778-8.278a1.1 1.1 0 010 1.556l-6 6a1.1 1.1 0 11-1.556-1.556l6-6a1.1 1.1 0 011.556 0z"
              fill="#ffffff"
            />
          </svg>
        </button>
      </div>
    );
  }
}
export default FeaturedPromotions;
