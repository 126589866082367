import React from "react";

import {dispatchSdKEvent} from "../_components/sdk";
import {fetchJSON} from "../_components/utils";

class WishlistButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inWishlist: false,
      loading: true,
    };
  }

  componentDidMount() {
    const { productHandle, customerId, wishlistProducts } = this.props;
    const here = this;
    fetch("/products/" + productHandle + ".js")
      .then((res) => res.json())
      .then((res) => {
        here.productID = res.id;
        if (customerId) {
          const found = wishlistProducts ? wishlistProducts.find((el) => el.id === res.id) : null;
          this.setState({ inWishlist: found, loading: false });
        } else {
          const wishlistList = JSON.parse(localStorage.getItem("EA-wishlist"));
          let isInWishlist = false;
          if (wishlistList) {
            const found = wishlistList.find((el) => el === res.id);
            if (found) {
              isInWishlist = true;
            }
          }
          this.setState({ inWishlist: isInWishlist, loading: false });
        }
      });
  }

  handleWishlistClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ loading: true });
    if (this.state.inWishlist) {
      this.removeFromWishlist();
    } else {
      this.addToWishlist();
    }
  };

  removeFromWishlist = () => {
    const { customerId } = this.props;
    const productID = this.productID;

    this.setState({ inWishlist: false });

    if (customerId) {
      fetchJSON(
        `/a/account/api/wishlist/${productID}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then(() => {
        this.setState({ loading: false });
      });
    } else {
      let wishlistList = JSON.parse(localStorage.getItem("EA-wishlist"));
      if (wishlistList) {
        wishlistList = wishlistList.filter((el) => el !== productID);
      } else {
        wishlistList = [];
      }
      localStorage.setItem("EA-wishlist", JSON.stringify(wishlistList));
      this.setState({ loading: false });
    }

    dispatchSdKEvent('ea-wishlist-updated'); // Dispatch an EA SDK event.

  };

  addToWishlist = () => {
    const { customerId } = this.props;
    const productID = this.productID;

    this.setState({ inWishlist: true });

    if (customerId) {
      fetchJSON(`/a/account/api/wishlist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ product_id: productID.toString() }),
      })
      .then(() => {
        this.setState({ loading: false });
      });
    } else {
      let wishlistList = JSON.parse(localStorage.getItem("EA-wishlist"));
      if (wishlistList) {
        wishlistList.push(productID);
      } else {
        wishlistList = [productID];
      }
      localStorage.setItem("EA-wishlist", JSON.stringify(wishlistList));
      this.setState({ loading: false });
    }

    dispatchSdKEvent('ea-wishlist-updated'); // Dispatch an EA SDK event.

  };

  emptyHeart = (styles) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        // style={{ height: "15px", width: "15px" }}
        className="ea-wishlist-icon"
      >
        {
          //Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
        }
        <path
          fill={styles.font_color}
          d="M244 84L255.1 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 0 232.4 0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84C243.1 84 244 84.01 244 84L244 84zM255.1 163.9L210.1 117.1C188.4 96.28 157.6 86.4 127.3 91.44C81.55 99.07 48 138.7 48 185.1V190.9C48 219.1 59.71 246.1 80.34 265.3L256 429.3L431.7 265.3C452.3 246.1 464 219.1 464 190.9V185.1C464 138.7 430.4 99.07 384.7 91.44C354.4 86.4 323.6 96.28 301.9 117.1L255.1 163.9z" />
      </svg>
    );
  }

  filledHeart = (styles) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        // style={{ height: "15px", width: "15px" }}
        className="ea-wishlist-icon"
      >
        {
          //Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
        }
        <path
          fill={styles.font_color}
          d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"
        />
      </svg>
    );
  }

  filledStar = (styles) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        // style={{ height: "15px", width: "15px" }}
        className="ea-wishlist-icon"
      >
        {
          //Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
        }
        <path
          fill={styles.font_color}
          d="M381.2 150.3L524.9 171.5C536.8 173.2 546.8 181.6 550.6 193.1C554.4 204.7 551.3 217.3 542.7 225.9L438.5 328.1L463.1 474.7C465.1 486.7 460.2 498.9 450.2 506C440.3 513.1 427.2 514 416.5 508.3L288.1 439.8L159.8 508.3C149 514 135.9 513.1 126 506C116.1 498.9 111.1 486.7 113.2 474.7L137.8 328.1L33.58 225.9C24.97 217.3 21.91 204.7 25.69 193.1C29.46 181.6 39.43 173.2 51.42 171.5L195 150.3L259.4 17.97C264.7 6.954 275.9-.0391 288.1-.0391C300.4-.0391 311.6 6.954 316.9 17.97L381.2 150.3z"
        />
      </svg>
    );
  }

  emptyStar = (styles) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
        // style={{ height: "15px", width: "15px" }}
        className="ea-wishlist-icon"
      >
        {
          //Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc.
        }
        <path
          fill={styles.font_color}
          d="M287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0L287.9 0zM287.9 78.95L235.4 187.2C231.9 194.3 225.1 199.3 217.3 200.5L98.98 217.9L184.9 303C190.4 308.5 192.9 316.4 191.6 324.1L171.4 443.7L276.6 387.5C283.7 383.7 292.2 383.7 299.2 387.5L404.4 443.7L384.2 324.1C382.9 316.4 385.5 308.5 391 303L476.9 217.9L358.6 200.5C350.7 199.3 343.9 194.3 340.5 187.2L287.9 78.95z"
        />
      </svg>
    );
  }

  getEmoji = (symbol, styles) => {
    var emojiComponent = "";
    const label = "ea-wishlist-icon";
    switch (symbol) {
      case "empty-heart":
        emojiComponent = this.emptyHeart(styles);
        break;
      case "filled-heart":
        emojiComponent = this.filledHeart(styles);
        break;
      case "empty-star":
        emojiComponent = this.emptyStar(styles);
        break;
      case "filled-star":
        emojiComponent = this.filledStar(styles);
        break;
      default:
        emojiComponent = "";
    }
    return (
      <span
        className="ea-wishlist-emoji-container"
        role="img"
        aria-label={label ? label : ""}
        aria-hidden={label ? "false" : "true"}
      >
        {emojiComponent}
      </span>
    );
  }

  getIcon = (styles) => {
    const { inWishlist } = this.state;

    if (styles.icon === "wishlist_icon-heart") {
      return inWishlist
        ?
        this.getEmoji("filled-heart", styles)
        :
        this.getEmoji("empty-heart", styles);
    }
    else if (styles.icon === "wishlist_icon-star") {
      return inWishlist
        ?
        this.getEmoji("filled-star", styles)
        :
        this.getEmoji("empty-star", styles);
    }
    else {
      return ""
    }
  }

  getText = (locale) => {
    const { loading, inWishlist } = this.state;
    return loading
      ? `...${locale.wishlist.loading}`
      : inWishlist
        ? locale.wishlist.remove_from_wishlist
        : locale.wishlist.add_to_wishlist;
  }

  getContent = (styles, locale) => {

    if (styles.content_type === "wishlist_content-text") {
      return this.getText(locale);
    }
    else if (styles.content_type === "wishlist_content-icon") {
      return this.getIcon(styles);
    }
    else {
      return (
        <React.Fragment>
          {this.getIcon(styles)} {this.getText(locale)}
        </React.Fragment>
      )
    }
  }

  getButtonStyle = (styles) => {
    var style = { color: styles.font_color, backgroundColor: styles.background_color }
    return style;
  }

  getClassList = (styles) => {
    var { classList } = this.props;

    var className = classList || "btn btn-default Button button btn--secondary-accent";

    if (styles.custom_css_class) {
      return className + ` ${styles.custom_css_class}`;
    }

    if (styles.type === "wishlist_only-content") {
      className = className + ' ea-wishlist-only-content';
    }

    if (styles.content_type === "wishlist_content-icon-with-text") {
      className = className + " ea-wishlist-icon-with-text";
    }

    if (styles.content_type === "wishlist_content-icon") {
      className = className + " ea-wishlist-only-icon"
    }

    return className;
  }

  render() {
    const { locale, classList, collectionPage, styles } = this.props;
    const { inWishlist } = this.state;
    const addedClass = inWishlist ? "ea-wishlist-added" : 'ea-wishlist-not-added';

    return (
      <button
        onClick={(e) => this.handleWishlistClick(e)}
        style={styles.custom_css_class ? {} : this.getButtonStyle(styles)}
        className={`${this.getClassList(styles)
        } ${
          collectionPage
            ? "ea-wishlist-collection-page-button"
            : "ea-wishlist-single-page-button"
        } ${addedClass}`}
      >
        {this.getContent(styles, locale)}
      </button>
    );
  }
}
export default WishlistButton;
