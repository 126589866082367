import React from "react";
import WishlistButton from "~/components/storefront/WishlistButton";
import CreditCheckout from "~/components/storefront/CreditCheckout";
import FeaturedPromotions from "~/components/storefront/FeaturedPromotions";
import {dispatchSdKEvent, setSdkData} from "~/components/_components/sdk";
import {fetchJSON} from "~/components/_components/utils/ProxyFetchUtils";
import { createRoot } from 'react-dom/client';
import gistAnalytics from "gist-analytics";


(function (
  gistAnalytics,
  FeaturedPromotions,
  CreditCheckout,
  WishlistButton,
  React
) {
  if (typeof EA != "undefined") {
    return false;
  }

  window.EA = {

    settings: null,
    credit: null,
    state: {
      pageType: null,
      wishlistProducts: [],
      currentTheme: null,
      customerId: null,
    },

    selectors: {
      checkoutBtn: `button[name="checkout"],
      input[name="checkout"],
      a[href="/checkout"],
      form[action="/cart"] input[type="submit"],
      form[action="/cart"] button[type="submit"],
      form[action="/cart/"] input[type="submit"],
      form[action="/cart/"] button[type="submit"],
      form[action="/cart"] button:not([type="button"]),
      form[action="/cart/"] button:not([type="button"])`,
      cartForm: `form[action^="/cart"]`
    },

    /**
     * Internal setup functions.
     */
    _: {
      /**
       * Get the translations accoreding to the current locale set in Shopify.locale
       */
      getLocales: function () {
        return new Promise((resolve, reject) => {
          fetchJSON(`/a/account/api/settings/locales/?locale=${Shopify.locale}`)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.warn("Locales could not be loaded", error);
            reject(error);
          });
        });
      },

      /**
       * Get settings based on the shop, and customer.
       */
      getSettings: () => {
        return new Promise((resolve, reject) => {

          let url = `/a/account/api/settings/`;

          fetchJSON(url)
          .then((settings) => {
            resolve(settings);
          })
          .catch((error) => {
            console.warn("Settings could not be loaded", error);
            reject(error);
          });
        });
      },

      /**
       * Logger which can be turned off and on for debugging production.
       */
      consoleLog: (v1, v2) => {

        if (typeof(EA.debug) != 'undefined' && EA.debug === true) {
          console.log(v1, v2);
        }

      },

      /**
       * Call each module of the application, and setup anything that is needed.
       */
      bootstrap: (values) => {

        EA._.consoleLog(values);

        var settings = values[0];

        EA.locale    = values[1];
        EA.shop      = settings.shop;
        EA.settings  = settings.shop.options;
        EA.credit    = settings.customer ? settings.customer.credit : null;
        EA.customer  = settings.customer ? settings.customer : null;

        // has plan that is active
        if (EA.settings.pricing_plan_id === null) {
          console.warn("Easy Accounts is installed, but not activated. Please contact your site administrator to enable the service.")
          return;
        }

        // app is enabled.
        if (!EA.settings.global.enabled) {
          console.warn("Easy Accounts is currently disabled");
          return;
        }

        EA._.setBodyClasses();

        EA._.getWishlistProducts();

        // Handle redirection to account if needed.
        EA.redirects.init();

        // Initialize promotions. show featured promotions on non account pages
        EA.promotions.init();

        // Initialize product page
        EA.productPage.init();

        // Initialize cart page.
        EA.cartPage.init();

        // Initialize collections page.
        EA.collectionPage.init();

        EA._.preloadAccountPageAssets();

      },

      /**
       * Send an error report to Gist gistAnalytics.
       */
      handleError: (error) => {
        console.warn("There was an error loading Easy Accounts settings", error);
        if (!error || !error.status) {
          var error = {
            status: 'unknown'
          }
        }
        gistAnalytics.insert("error", error.status, {
          shop_id: "{{shop.id}}",
          message: "API Error",
          url: `/a/account/api/settings`,
          component: "easyaccount.js",
          body: "",
          stack: "front-end",
          location: window.location.href,
        });
      },

      setBodyClasses: () => {

        if (EA.state.pageType === 'cart') {
          document.body.classList.add('ea-cart-page');
        }

        if (EA.state.currentTheme === "dawn") {
          document.body.classList.add('ea-dawn-theme');
        }

      },

      /**
       * ge the wishlist products for the current state.
       */
      getWishlistProducts: () => {
        EA.state.wishlistProducts =  EA.customer && EA.customer.wishlist && Array.isArray(EA.customer.wishlist) ? EA.customer.wishlist : [];
        return EA.state.wishlistProducts;
      },

      /**
       * Fetch the contents of the proxy page files, so that they are loaded
       * in the browsers cache prior to hitting the account page.
       */
      preloadAccountPageAssets: async () => {

        let baseUrl = process.env.HOST_URL;
        let appEnv = process.env.APP_ENV;

        if (appEnv === 'production') {
          baseUrl = "https://cdn.easyaccounts.app";
        }

        fetch(`${baseUrl}/packs/proxy.js`)
        .then(r => {
          
        })
        .catch(err => {
        });
        // fetch(`${baseUrl}/packs/proxy.css`); // This does not exist

      },

      /**
       * Get the current type of page for the Shopify store.
       * Valid return values are easyaccounts_dashboard, account, product, cart, home, thank_you, collection
       */
      getPageType: () => {
        return new Promise((resolve, reject) => {
          if (typeof(meta) != 'undefined' &&  meta && meta.product && meta.product.id) {
            EA.state.pageType = "product";
            resolve(EA.state.pageType);
          }

          if (
            typeof(meta) != 'undefined' && meta && meta.page &&
            meta.page.pageType &&
            meta.page.pageType === "collection"
          ) {
            EA.state.pageType = "collection";
            return resolve(EA.state.pageType);
          }

          const accountRegex = /^\/a\/account/;
          const accountMatch = window.location.pathname.match(accountRegex);

          if (accountMatch) {
            EA.state.pageType = "easyaccounts_dashboard";
            return resolve(EA.state.pageType);
          }

          const stdAccountRegex = /^\/account/;
          const stdAccountMatch =
            window.location.pathname.match(stdAccountRegex);

          if (stdAccountMatch) {
            EA.state.pageType = "account";
            return resolve(EA.state.pageType);
          }

          if (window.location.href.includes("cart")) {
            EA.state.pageType = "cart";
            return resolve(EA.state.pageType);
          }

          resolve("unknown");
        });
      },

      /**
       * Grab the customer id from the window.
       */
      getCustomerId: () => {

        try {
          let curr = window.meta.page.customerId;
          if (curr !== undefined && curr !== null && curr !== "") {
            EA.state.customerId = curr;
            return EA.state.customerId;
          }
        } catch (e) {}

        // TODO: The below makes no sense, and should be removed.
        try {
          let curr = _st.cid;
          if (curr !== undefined && curr !== null && curr !== "") {
            EA.state.customerId = curr;
            return EA.state.customerId;
          }
        } catch (e) {}
        return null;
      },

      /**
       * Get the name of the current theme, based on the theme_store_id
       */
      getCurrentTheme: () => {

        if (!Shopify || !Shopify.theme || !Shopify.theme.theme_store_id) {
          return null;
        }

        if (Shopify.theme.theme_store_id === 887) {
          EA.state.currentTheme = "dawn";
        }

        return EA.state.currentTheme;

      },

      /**
       * Useful function to stop a javascript event from continuing
       * @param {event} event
       */
      stopEvent: function(event, reason = null) {

        if (window.GIST && window.GIST.app_debug && window.GIST.app_debug === true) {
          console.log("Giftship is stopping event", reason, event);

        }

        if (typeof(event) === null) {
          return false;
        }
        if (event.preventDefault) {
            event.preventDefault();
        } else {
            event.stop();
        };
        event.returnValue = false;
        event.stopPropagation();
        event.stopImmediatePropagation();
      }
    },

    /**
     * Initialize the application.
     */
    init: () => {

      gistAnalytics.configure(process.env.GIST_ANALYTICS_API_KEY);

      EA._.getCurrentTheme();
      EA._.getCustomerId();
      EA._.getPageType();

      if (EA.state.pageType === "easyaccounts_dashboard") return;

      Promise
      .all([EA._.getSettings(), EA._.getLocales()])
      .then(EA._.bootstrap)
      .catch(EA._.handleError)
      ;

    },


  };

  /**
   * Handles all functions and events that occur on the cart page.
   */
  EA.cartPage = {
    useCredit: false,

    init: function () {
      if (EA.state.pageType !== "cart") return;
      this.storeCredit();
      this.events();
    },

    events: function () {

      // get last visible element in results
      const submitBtn = EA.f.lastVisible(
        document.querySelectorAll(EA.selectors.checkoutBtn)
      );

      const cartForms = document.querySelectorAll('form[action="/cart/"], form[action="/cart"], form[action="/checkout"], form[action="/checkout/"]');

      // Add listener to submit button. The other events won't run if we handle on click
      submitBtn.addEventListener("click", EA.cartPage.actions.handleCheckoutClick);

      // add listener to cart form to interrupt
      cartForms.forEach((form) => {
        form.addEventListener("submit", EA.cartPage.actions.handleCheckoutSubmit);
      });



    },

    actions: {

      submitDiscountCodeCheckout: (event) => {

        try {

          event.target.setAttribute(
            "action",
            "/checkout?discount=" + EA.credit.code
          );

          event.target.submit();

        } catch(error) {

          EA.cartPage.actions.redirectToCheckout(null);

        }


      },

      buildDraftOrderRequest: (cart) => {

        return new Promise((resolve, reject) => {

          try {
            const lineItems = cart.items.map((item) => ({
              variant_id: item.variant_id,
              quantity: item.quantity,
            }));

            const requestBody = {
              line_items: lineItems,
              discount: {
                "title": "Store credit",
                "description": "Store credit",
                "value": EA.credit.summary.current_balance,
                "value_type": "fixed_amount",
                "amount": EA.credit.summary.current_balance
              }
            };

            resolve(requestBody);
          } catch (e) {
            reject(e);
          }


        });


      },

      generateDraftOrder: (requestBody) => {

        return new Promise((resolve, reject) => {

          try {

            const params = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify(requestBody),
            };

            fetchJSON(`/a/account/api/draft_orders`, params)
            .then((draftOrder) => {
              resolve(draftOrder);
            })
            .catch((error) => {
              reject(error);
            })
            ;

          } catch (e) {
            reject(e);
          }

        });

      },

      redirectToCheckout: (draftOrder) => {

        if (!draftOrder || !draftOrder.invoice_url) {
          window.location.href = "/checkout?discount=" + EA.credit.code;
          return false;
        }

        window.location.href = draftOrder.invoice_url;

      },

      submitDraftOrderCheckout: () => {

        fetch("/cart.js")
          .then((cart) => cart.json())
          .then((cart) => EA.cartPage.actions.buildDraftOrderRequest(cart))
          .then((requestBody) => EA.cartPage.actions.generateDraftOrder(requestBody))
          .then((draftOrder) => EA.cartPage.actions.redirectToCheckout(draftOrder))
          .catch((error) => {

            console.warn("There was an error generating the store credit checkout. Redirecting the regular checkout page.", error);
            EA.cartPage.actions.redirectToCheckout(null);

          });

      },
      handleCheckoutClick: (event) => {

        if (typeof EA.checkoutSubmitted != "undefined" || EA.cartPage.useCredit === false) {
          return;
        }

        EA._.stopEvent(event);

        EA.checkoutSubmitted = true;

        // discount code
        if (EA.settings.credits.type === "discount_code") {
          window.location.href = "/checkout?discount=" + EA.credit.code;
          return;
        }
        // draft order
        if (EA.settings.credits.type === "draft_order") {
          return EA.cartPage.actions.submitDraftOrderCheckout();
        }
        // gift card
        if (EA.settings.credits.type === "gift_card") {
          console.log("todo: gift card");
        }
      },

      handleCheckoutSubmit: (event) => {

        if (typeof EA.checkoutSubmitted != "undefined" || EA.cartPage.useCredit === false) {
          return;
        }

        event.preventDefault();

        EA.checkoutSubmitted = true;

        // discount code
        if (EA.settings.credits.type === "discount_code") {
          return EA.cartPage.actions.submitDiscountCodeCheckout(event);
        }
        // draft order
        if (EA.settings.credits.type === "draft_order") {
          return EA.cartPage.actions.submitDraftOrderCheckout();
        }
        // gift card
        if (EA.settings.credits.type === "gift_card") {
          console.log("todo: gift card");
        }
      }

    },
    /**
     * Handles adding a store credit widget to the cart page.
     */
    storeCredit: function () {
      // get last visible element in results
      const submitBtn = EA.f.lastVisible(
        document.querySelectorAll(EA.selectors.checkoutBtn)
      );

      const cartForm = EA.f.lastVisible(
        document.querySelectorAll(EA.selectors.cartForm)
      );

      const creditContainer = document.createElement("div");

      if (typeof submitBtn == "undefined") return;
      if (!EA.state.customerId) return;
      if (!EA.credit) return;
      if (!EA.credit.summary) return;
      if (!EA.credit.summary.current_balance) return;
      if (EA.credit.summary.current_balance <= 0) return;

      // there is a credit and the current balance isn't 0
      // create and add the checkbox to the page

      creditContainer.setAttribute("id", "EA-credit-container");

      creditContainer.classList.add('ea-dawn-theme');

      document.body.classList.add('ea-credit-feature-visible');

      if (EA.state.currentTheme === "dawn") {
        cartForm.append(creditContainer);
      } else {
        submitBtn.parentNode.insertBefore(
          creditContainer,
          submitBtn
        );
      }


      const container = document.getElementById('EA-credit-container');

      if (!container) return;

      const root = createRoot(container);

      root.render(
        <CreditCheckout
          customerId={EA.state.customerId}
          settings={EA.settings}
          customer={EA.customer}
          locale={EA.locale}
          currentBalance={EA.credit.summary.current_balance}
          setUseCredit={(v) => {
            EA.cartPage.useCredit = v;
          }}
          currency={EA.settings.currency}
        />
      );


    },
  };

  /**
   * Handles all functions and events that occur on the collection page.
   */
  EA.collectionPage = {
    init: function () {
      // collection page wishlist
      this.collectionWishlist();
    },

    /**
     * Adds wishlist button to the products on a collection page.
     */
    collectionWishlist: function () {
      const eaWishlists = document.querySelectorAll(".ea-wishlist");


      if (!eaWishlists.length <= 0) return;

      if (EA.state.pageType !== "collection") return;

      if (!EA.settings.account_page.wishlist.enabled) return;

      eaWishlists.forEach((wishlist) => {
        const productHandle = wishlist.getAttribute("data-product-handle");

        if (productHandle) {

          const root = createRoot(wishlist);

          root.render(
            <WishlistButton
              productHandle={productHandle}
              customerId={EA.state.customerId}
              locale={EA.locale}
              wishlistProducts={EA.state.wishlistProducts}
              styles={styles}
              collectionPage
            />
          );

        }
      });
    },
  };

  /**
   * Handle all product page specific functions.
   */
  EA.productPage = {
    init: function () {

      if (EA.state.pageType !== "product") return;

      // register events
      this.events();

      // product page wishlist
      this.wishlist();

      // initialize product reviews
      this.reviews.init();
    },

    reviews: {

      state: {
        submitted: false
      },

      init: function() {

        if (!EA.settings.integrations.product_review.integrated) return false;

        this.events();

      },

      events: function() {

        document.addEventListener("submit", this.actions.handleReviewSubmit);

      },

      mutations: {
        /**
         * get the email from the emailInput
         * @param {*} emailInput
         * @returns email if the email is found
         * @returns null if the emailInput is not valid or the email is not found in the emailInput
         */
        getEmail: function (emailInput) {
          if (!emailInput || !emailInput.length) return null;
          const email = emailInput[0].value;
          if (!email) return null;
          return email;
        },
        /**
         *
         * @returns true/false(boolean)
         */
        isReviewSuccess: function () {
          const success = document.querySelectorAll(".spr-form-message-success");
          return success && success.length > 0 ? true : false;
        },
        /**
         *
         * @param {*} email
         * @returns {email, productId} if valid otherwise null
         */
        buildRequestBody: function (email) {
          const reviews = document.querySelectorAll("#shopify-product-reviews");
          if (!reviews || !reviews.length) return null;
          const productId = reviews[0].getAttribute("data-id");
          if (!productId) return null;
          return {
            email,
            product_id: productId,
          };
        },
        /**
         *
         * @param {email, productId} body
         *
         */
        makeRequest: function (body) {
          fetchJSON(
            `/a/account/api/reviews/product_reviews`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify(body),
            }
          )
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              gistAnalytics.insert("error", res.status, {
                shop_id: "{{shop.id}}",
                message: "API Error",
                url: `/a/account/api/reviews/product_reviews`,
                component: "easyaccount.js",
                body: JSON.stringify(body),
                stack: "front-end",
                location: window.location.href,
              });
              throw new Error("");
            }
          })
          .then(
            (res) => { },
            (error) => {
              // error should be caught, do nothing
            }
          );
        },

        watch: function (emailInput) {
          const email = EA.productPage.reviews.mutations.getEmail(emailInput);
          if (!email) return;
          var observer = new MutationObserver(function(mutations) {

            if (!EA.productPage.reviews.mutations.isReviewSuccess()) return;

            const body = EA.productPage.reviews.mutations.buildRequestBody(email);

            if (!body) return;

            observer.disconnect();

            EA.productPage.reviews.mutations.makeRequest(body);

          });
          observer.observe(document, {
            attributes: false,
            childList: true,
            characterData: false,
            subtree: true,
          });
        }
      },

      actions: {

        // Retreive the form action from the current form being submitted.
        getFormAction: function(event) {

          let formAction          = event.target && event.target.getAttribute("action");
          let activeElementAction = document.activeElement.getAttribute("formaction");
          let action              = activeElementAction || formAction;

          return action;

        },

        /**
         * Handle our generic document submit action, and ensure we are on the review
         * form.
         */
        reviewFormValid: function(formAction, productReviewsForm, emailInput) {

          if (!formAction || formAction !== "//productreviews.shopifycdn.com/api/reviews/create") return false;
          if (!productReviewsForm.length || !emailInput.length) return false;
          if (!EA.settings.integrations.product_review.integrated) return false;
          if (EA.productPage.reviews.state.submitted === true) return false;

          return true;

        },


        handleReviewSubmit: function(event) {

          const formAction         = EA.productPage.reviews.actions.getFormAction(event);
          const productReviewsForm = document.querySelectorAll('form[action="//productreviews.shopifycdn.com/api/reviews/create"]');
          const emailInput         = document.querySelectorAll('form[action="//productreviews.shopifycdn.com/api/reviews/create"] input.spr-form-input-email');
          // Make sure we're in the right spot.
          if (EA.productPage.reviews.actions.reviewFormValid(formAction, productReviewsForm, emailInput) !== true) return;

          EA.productPage.reviews.state.submitted = true;

          EA.productPage.reviews.mutations.watch(emailInput);

          event.preventDefault();

        }

      }

    },

    /**
     * Handles wishlist on the product page.
     */
    wishlist: function () {
      var productHandle = window.location.pathname.match(/\/products\/(.*)/);

      if (!productHandle[1]) return;

      if (EA.state.pageType !== "product") return;

      if (!EA.settings.account_page.wishlist.enabled) return;

      const styles = EA.shop.page_design.wishlist;

      productHandle = productHandle[1];
      // find ea-wishlist inserted
      const eaWishlistSpecific = document.querySelectorAll(".ea-wishlist");
      const addToCartButton = EA.f.firstVisible(
          document.querySelectorAll(
          `form[action*="/cart/add"] [type="submit"], .ea-addtocart-btn`
        )
      );

      if (eaWishlistSpecific.length > 0) {

        const root = createRoot(eaWishlistSpecific[0]);

        root.render(
          <WishlistButton
            productHandle={productHandle}
            customerId={EA.state.customerId}
            locale={EA.locale}
            classList={typeof(addToCartButton) != 'undefined' ? addToCartButton.classList.value : "ea-default-wishlist"}
            wishlistProducts={EA.state.wishlistProducts}
            styles={styles}
          />
        );

      } else if (typeof(addToCartButton) == 'undefined' ) {

        // else just add it before cart
        console.error("Easy Accounts:", "Wishlist could not be inserted, as the add-to-cart button could not be found on this page. To fix this error, and remove this from the console, please add the class 'ea-addtocart-btn' to  your product forms submit button");

      } else {
        // else just add it before cart
        EA.wishlistContainer = document.createElement("div");
        // EA.wishlistContainer.id = "ea-wishlist";
        EA.wishlistContainer.classList.add("ea-wishlist");
        addToCartButton.after(EA.wishlistContainer);

        const root = createRoot(EA.wishlistContainer);

        root.render(
          <WishlistButton
            productHandle={productHandle}
            customerId={EA.state.customerId}
            locale={EA.locale}
            classList={addToCartButton.classList.value}
            wishlistProducts={EA.state.wishlistProducts}
            styles={styles}
          />
        );

      }
    },

    /**
     * Register all events to listen for on the product page.
     */
    events: function () {

    },

    handleBuyNow: function (e) {
      const addToCartForm = e.target.closest("form[action^='/cart/add']");
      const formData = new FormData(addToCartForm);
      // add selected variant to cart
      fetch("/cart/add.js", {
        method: "POST",
        body: formData,
      })
      .then((res) => res.json())
      .then((res) => {
        // go to checkout page
        window.location.pathname = "/checkout";
      });

      e.preventDefault();
      e.stopPropagation();
    },
  };

  /**
   * Manage redirections within the application.
   */
  EA.redirects = {
    /**
     * Gets the link to the easy accounts dashboard page. This differs based
     * on the locale.
     */
    getAccountLink: () => {
      var redirectUrl = `${Shopify.routes.root}a/account`;

      /**
       *  this is assuming the site domain is in the default structure. If the merchant is a Plus Merchant
       *  we can add a script tag and check for its existence here.
       * */
      if (EA.shop.primary_locale !== Shopify.locale) {
        redirectUrl = `${Shopify.routes.root}a/account?locale=${Shopify.locale}`;
      }

      return redirectUrl;
    },

    /**
     * Embed the redirect urls to the customer login form, if the login form is present.
     */
    loginFormRedirect: function () {
      // redirect login to /a/account
      const CustomerLoginForm = document.querySelector(
        `form[action$="/account/login"]`
      );

      const RedirectInput = document.createElement("input");

      const RedirectURLInput = document.createElement("input");

      const RedirectUrl = this.getAccountLink();

      if (!CustomerLoginForm) {
        return false;
      }

      RedirectInput.setAttribute("type", "hidden");
      RedirectInput.setAttribute("name", "return_to");

      RedirectURLInput.setAttribute("type", "hidden");
      RedirectURLInput.setAttribute("name", "return_url");
      RedirectURLInput.setAttribute("value", RedirectUrl);

      CustomerLoginForm.appendChild(RedirectInput);
      CustomerLoginForm.appendChild(RedirectURLInput);
    },
    /**
     * Change all account links to be links to the easy accounts customer login
     * page.
     */
    accountLinkRedirect: function () {
      // redirect /account link to /a/account
      const AccountLinkLocales = document.querySelectorAll(`a[href$="/account"]:not([href*="/a/account"])`);

      const RedirectUrl = this.getAccountLink();

      // links exist and customer is logged in
      if (AccountLinkLocales.length > 0 && EA.state.customerId) {
        AccountLinkLocales.forEach((AccountLinkLocale) => {
          let NewLink = AccountLinkLocale.cloneNode(true);
          NewLink.setAttribute("href", RedirectUrl);
          AccountLinkLocale.parentNode.replaceChild(NewLink, AccountLinkLocale);
        });

        return false;
      }
    },
    /**
     * Checks for specific pages, and redirects to the Easy Accouts app dashboard.
     */
    directVisitRedirect: function () {
      // if ever they go to /account directly, redirect them
      const accountRegex =
        /^\/(?!a\/)account(?!\/(login|register|reset|invalid_token|activate|unsubscribe))/;
      const accountMatch = window.location.pathname.match(accountRegex);

      const hasError = window.location.href.includes('eae=true');

      if (accountMatch && hasError === false) {
        window.location.href = this.getAccountLink();
      }
    },

    /**
     * App is disabled, but we're on the easy accounts dashboard page.
     */
    appDisabledRedirect: function () {
      if (
        !EA.settings.account_page.enabled && EA.state.pageType === "easyaccounts_dashboard"
      ) {
        console.warn("Easy Accounts account page is disabled, and we are currently within the proxy page. This means there are hard-coded links within the theme which need to be removed.")
        window.location.pathname = "/account?eae=true&from_disabled=true";
      }
    },

    /**
     * Handles redirecting to the appropriate account page, based on settings
     * in Easy Accounts admin. If dash is disabled, and on account page currently,
     * redirect to the regular account page.
     */
    init: function () {
      // if account page is disabled and we are on a proxy page, redirect to the regular customer account page.
      this.appDisabledRedirect();

      // If the account page is disabled, don't do anything.
      if (!EA.settings.account_page.enabled) {
        return false;
      }

      // redirect login form to /a/account
      this.loginFormRedirect();

      // redirect /account links to /a/account
      this.accountLinkRedirect();

      // redirect /account locations to /a/account
      this.directVisitRedirect();
    },
  };

  /**
   * This is the main promotions widget module.
   */
  EA.promotions = {
    /**
     * Placholder object for featured promotions
     */
    featured: [],

    init: function () {
      this.addWidget();

      this.handleReferralCode();
    },

    /**
     * Handle retrieving a referral code from the url.
     */
    handleReferralCode: function () {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("_earef")) {
        EA.customer_reference = urlParams.get("_earef");
        // call the cart to set the order note attr
        fetch("/cart/update.js", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            attributes: { referral: EA.customer_reference },
          }),
        });
        // set it in session storage
        window.sessionStorage.setItem("_earef", EA.customer_reference);
      }
    },

    /**
     * Get featured promotions for the shop, and those specific to the logged in
     * customer.
     */
    getFeatured: function () {

      if (EA.customer && EA.customer.promotions) {
        this.featured = this.featured.concat(EA.customer.promotions);
      } else if (EA.shop.promotions) {
        this.featured = this.featured.concat(EA.shop.promotions);
      }

      this.featured = this.featured.filter((promotion) => {
        return promotion.featured === true && promotion.customer_viewable === true
      })

      return this.featured;
    },
    /**
     * Embed the widget into the storefront. If we are on the account page, we don't add
     * it in, as it is already displayed.
     */
    addWidget: function () {
      this.getFeatured();

      if (EA.state.pageType === "easyaccounts_dashboard") return;

      if (EA.promotions.featured.length <= 0) return;

      if (!EA.settings.promotions.enabled) return;

      if (!EA.settings.promotions.widget_enabled) return;

      const body = document.querySelector("body");
      const promoDiv = document.createElement("div");

      promoDiv.id = "EA-featured-promotions";

      body.insertBefore(promoDiv, body.firstChild);

      const root = createRoot(promoDiv);

      root.render(
        <FeaturedPromotions
          pageDesign={EA.shop.page_design}
          promotions={EA.promotions}
          customerId={EA.state.customerId}
          locale={EA.locale}
        />
      );

    },
  };

  /**
   * Add global non module related functions to the window.
   */
  EA.f = {
    docReady: function (fn) {
      // see if DOM is already available
      if (
        document.readyState === "complete" ||
        document.readyState === "interactive"
      ) {
        // call on next available tick
        setTimeout(fn, 1);
      } else {
        document.addEventListener("DOMContentLoaded", fn);
      }
    },

    loadStyles: async function (reinit, callback) {

      if (reinit === true) {
        callback();
      }

      if (window.location.href.indexOf("checkout.shopify.com") >= 0) {
        return false;
      }

      let cdnUrl = process.env.CDN_URL;

      var pathToCSS = cdnUrl + "/css/easyaccounts.css";


      var styleSheets = document.styleSheets;

      for (var i = 1, max = styleSheets.length; i < max; i++) {
        if (styleSheets[i].href == pathToCSS) {
          // if stylesheets already exists, do callback and end here
          callback();
          return;
        }
      }

      var link = document.createElement("link");
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = pathToCSS;
      link.media = "all";

      document.getElementsByTagName("head")[0].appendChild(link);
      callback();
    },

    lastVisible: function (collection) {
      var el = undefined;

      if (collection.length <= 0) {
        return el;
      }

      for (let i = 0; i < collection.length; i++) {
        if (collection[i].offsetWidth > 0 && collection[i].offsetHeight > 0) {
          el = collection[i];
        }
      }

      return el;
    },
    firstVisible: function (collection) {
      var el = undefined;

      if (collection.length <= 0) {
        return el;
      }

      for (let i = 0; i < collection.length; i++) {
          if (typeof(el) == 'undefined' && collection[i].offsetWidth > 0 && collection[i].offsetHeight > 0) {
            el = collection[i];
          }
      }

      return el;
    },
  };

  EA.f.loadStyles(false, function () {

  });

  EA.f.docReady(function () {
    EA.init();
  });

})(
  gistAnalytics,
  FeaturedPromotions,
  CreditCheckout,
  WishlistButton,
  React
);
