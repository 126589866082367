
/**
 *  @var lang - parse the language from the browsers navigator globals.
 */
const lang = navigator.languages
  ? navigator.languages[0]
  : navigator.language || navigator.userLanguage;

/**
 * Format the currency using Intl library
 *
 * @param Integer amount - the amount of the money in cents? .. dollars?
 * @param String currency - the 3 letter ISO currency code.
 * @param String locale - The current locale of the user in 2 letter ISO country code.
 *
 * @return String - the formatted currency.
 */
export const currencyFormat = (amount, currency, locale) => {
  if (currency && (locale || lang)) {
    return new Intl.NumberFormat(locale || lang, {
      style: "currency",
      currency: currency,
    }).format(amount);
  } else {
    return parseFloat(amount).toFixed(2);
  }
};

/**
 *
 */
export const formatMoney = function (cents, format) {
  if (typeof cents == 'string') { cents = cents.replace('.', ''); }
  var value = '';
  var placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  var formatString = format;

  function defaultOption(opt, def) {
    return (typeof opt == 'undefined' ? def : opt);
  }

  function formatWithDelimiters(number, precision, thousands, decimal) {
    precision = defaultOption(precision, 2);
    thousands = defaultOption(thousands, ',');
    decimal = defaultOption(decimal, '.');

    if (isNaN(number) || number == null) { return 0; }

    number = (number / 100.0).toFixed(precision);

    var parts = number.split('.'),
      dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + thousands),
      cents = parts[1] ? (decimal + parts[1]) : '';

    return dollars + cents;
  }

  switch (formatString.match(placeholderRegex)[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',');
      break;
  }

  return formatString.replace(placeholderRegex, value);
};

/**
 *
 */
export const moneyWithCurrency = (cents, format, currency) => {
  const formatted_money = formatMoney(cents, format);
  return `${formatted_money} ${currency}`
}
