import React, {useState, useEffect} from "react";

import {cookieFunctions, currencyFormat} from "../_components/utils";

function CreditCheckout({
  customer,
  settings,
  locale,
  currentBalance,
  currency
}){

  const [useCredit, setUseCredit] = useState(EA.cartPage.useCredit || false);

  /**
   * Update form attributes to include the discount in the action.
   * @param {HTMLElement} form - cart form
   */
  const setFormAttribute = (form) => {

    const newAction = '/cart?discount=' + customer.credit.code;
    const currentAction = form.getAttribute('action');

    form.setAttribute('action', newAction);
    form.setAttribute('data-original-action', currentAction);

  }

  /**
   * Reset the cart forms back to their original form action when unchecked.
   * @param {HTMLElement} form - cart form
   */
  const resetFormAttribute = (form) => {

    const originalAction = form.getAttribute('data-original-action');
    const newAction = originalAction !== null && originalAction.includes('/cart') ? originalAction : "/cart";

    form
    .setAttribute('action', newAction)
    ;

  }

  /**
   * Update the form actions based on the curren checkbox state.
   * @param {isChecked}
   */
  const updateFormAttributes = (isChecked) => {

    const cartForms = document.querySelectorAll("form[action^='/cart']");

    cartForms.forEach((form, index) => {

      if (isChecked === false) {
        resetFormAttribute(form);
      } else {
        setFormAttribute(form);
      }

    });

  }

  /**
   * Handle updating the discount code cookie whenever the checkbox changes.
   * @param {boolean} remove
   */
  const updateDiscountCookie = (isChecked) => {

    if (isChecked === false) {
      cookieFunctions.erase('discount');
      cookieFunctions.erase('discount_code');
      return;
    }

    cookieFunctions.create('discount', customer.credit.code, 7);
    cookieFunctions.create('discount_code', customer.credit.code, 7);

  }

  /**
   * Handle the click event on the store credit checkbox.
   */
  const handleCheckboxChange = (e) => {

    e.stopPropagation();

    const isChecked = !useCredit;

    EA.cartPage.useCredit = isChecked;

    setUseCredit(isChecked);

  };

  useEffect(() => {

    if (settings.credits.type !== "discount_code" || !customer.credit.code) {
      return;
    }

    updateDiscountCookie(useCredit);

    updateFormAttributes(useCredit);

    updateDiscountCookie(useCredit);

  }, [useCredit]);


  return (
    <div id="EA-credit-box">
      <label>
        {locale.store_credit.you_have.replace(
          "{amount}",
          currencyFormat(currentBalance, currency, Shopify.locale)
        )}
      </label>
      <label htmlFor="EA-credit-checkbox" id="EA-credit-label" className="ea-checkbox-label">
        {settings.credits.type === "discount_code" ? (
          <input
            id="EA-credit-checkbox"
            name="discount"
            value={customer.credit.code}
            type="checkbox"
            className="ea-checkbox"
            onChange={handleCheckboxChange}
            checked={useCredit}
          />
        ) : (
          <input
            id="EA-credit-checkbox"
            name="attributes[easy_accounts_credit]"
            value={customer.credit.current_balance}
            type="checkbox"
            className="ea-checkbox"
            onChange={handleCheckboxChange}
            checked={useCredit}
          />
        )}
        <span className="ea-checkbox gs__rectangular"></span>
        <div className="ea-checkbox-input-title">{locale.store_credit.use_credit_in_checkout}</div>
      </label>

    </div>
  );

}
export default CreditCheckout;
