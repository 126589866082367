/**
 * Build an object from the current query params
 */
export const getParams = (url) => {

  const search = url.split("?")[1];

  const urlSearchParams = new URLSearchParams(`?${search}`);

  const params = Object.fromEntries(urlSearchParams.entries());

  return params;

}

/**
 * Generate url search params from a Proxy object
 */
export const generateParams = (params) => {

  var str = [];

  for (var p in params) {

    if (params.hasOwnProperty(p) && typeof(params[p]) != 'undefined' && typeof(p) != 'undefined' && p != 'undefined' && params[p] != 'undefined') {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }

  }
  return str.join("&");


}



export const fetchJSON = (url, options) => {

  return new Promise((resolve, reject) => {

    if (options) {
      fetch(url, options)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.message;
        }
      })
      .then((result) => {
        console.log("result", result);
        if ((result && result.success !== false) || result === null) {
          resolve(result);
        } else if (result && typeof(result.message) != 'undefined') {
          reject(result.message);
        } else {
          console.warn("Fetch request did not return a successfull result, or a message");
          resolve(result);
        }
      });
    }
    else {
      const params = getParams(url);
      const baseUrl = url.split("?")[0];
      const newUrl = `${baseUrl}?${generateParams(params)}`;

      fetch(newUrl, options)
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            reject(res.message);
          }
        })
        .then((result) => {
          if ((result && result.success !== false) || result === null) {
            resolve(result);
          } else if (result && result.message) {
            reject(result.message);
          } else {
            reject(result);
          }
        });
    }
  });

};


export const fetchJSONStatus = (url, options) => {

  return new Promise((resolve, reject) => {

    if (options) {
      fetch(url, options)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          reject(res);
        }
      })
      .then((result) => {
        if ((result && result.success !== false) || result === null) {
          resolve(result);
        } else {
          reject(result);
        }
      });
    } else {

      const params = getParams(url);
      const baseUrl = url.split("?")[0];
      const newUrl = `${baseUrl}?${generateParams(params)}`;

      fetch(newUrl, options)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          reject(res);
        }
      })
      .then((result) => {
        if ((result && result.success !== false) || result === null) {
          resolve(result);
        } else {
          reject(result);
        }
      });
    }

  });

}

export const fetchHTMLStatus = (url, options) => {

  return new Promise((resolve, reject) => {

    if (options) {
      fetch(url, options)
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          reject(res.status);
        }
      })
      .then((result) => {
        if ((result && result.success !== false) || result === null) {
          resolve(result);
        } else {
          reject(result.status);
        }
      });

    }
    else {
      const params = getParams(url);
      const baseUrl = url.split("?")[0];
      const newUrl = `${baseUrl}?${generateParams(params)}`;
      fetch(newUrl, options)
        .then((res) => {
          if (res.ok) {
            return res.text();
          } else {
            reject(res.status);
          }
        })
        .then((result) => {
          if ((result && result.success !== false) || result === null) {
            resolve(result);
          } else {
            reject(result.status);
          }
        });

    }

  });

};

export const fetchJSONURL = (url, options) => {

  return new Promise((resolve, reject) => {

    if (options) {
      fetch(url, options)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          reject(url);
        }
      })
      .then((result) => {
        if ((result && result.success !== false) || result === null) {
          resolve(result);
        } else {
          reject(url);
        }
      });
    }
    else {
      const params = getParams(url);

      const baseUrl = url.split("?")[0];
      const newUrl = `${baseUrl}?${generateParams(params)}`;

      fetch(newUrl, options)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          reject(url);
        }
      })
      .then((result) => {
        if ((result && result.success !== false) || result === null) {
          resolve(result);
        } else {
          reject(url);
        }
      });
    }

  });

};
